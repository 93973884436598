import React, { useContext, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { API } from 'aws-amplify'
import { Link } from 'gatsby'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import {
  Grid,
  Card,
  CardHeader,
  IconButton,
  Box,
  Typography,
  Button,
  CardContent,
  CircularProgress,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import QrCodeIcon from '@mui/icons-material/QrCode'

import SmartphoneIcon from '@mui/icons-material/Smartphone'
import InfoIcon from '@mui/icons-material/Info'
import Hider from '../utils/Hider'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { navigate } from 'gatsby'
import {gUtils} from "../utils/gutils";

export default function AddDevice({
  location,
  open,
  setOpen,
  setLimitWarning,
  limitWarning,
    gCfp,

}) {
  const [keyList, setKeyList] = useState([])
  const { gStripe, ts } = useContext(
    GlobalStateContext,
  )
  const [getPersonalDevice, setGetPersonalDevice] = useState(false)

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  })


  useEffect(() => {

    if (location.search) {
      API.post('Attendance', `/oauth`, {
        body: location.search.split(/[=,&]/)[1],
      })
        .then((res) => {
          setKeyList(
            keyList
              .concat(res.Items)
              .filter((row) => row.deviceModel !== 'wm_2'),
          )
          setOpen(true)
        })
        .catch((err) => console.log(err))
    }
  }, [location.search])

  useEffect(() => {
    if (gStripe.customer.id) {
      setLimitWarning(false)
      setGetPersonalDevice(false)
      setKeyList([])
      navigate(`/devices`)
    }
  }, [gStripe.customer.id])

  return (
    <Card sx={{ mb: '15px' }}>
      <CardHeader
        title={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h2" sx={{ ml: '9px' }}>
              新規デバイスを追加
            </Typography>
            <IconButton
              onClick={() => {
                setOpen(!open)
              }}
              sx={{ '&:hover': { color: 'primary.main' } }}
            >
              <Hider show={open}>
                <KeyboardArrowDownIcon />
              </Hider>
            </IconButton>
          </Box>
        }
      />
      <CardContent>
        <Box sx={{ display: 'flex' }}>
          <Typography>新規デバイスの追加方法を選択</Typography>
          <CustomWidthTooltip
            title={
              <div>
                【QRコード】QRコードからデバイスをインポート
                <br />
                【個人で登録済のデバイス】個人でセサミアプリに登録済みのデバイスをインポート
              </div>
            }
            placement="right-start"
          >
            <InfoIcon sx={{ color: '#cccccc', cursor: 'pointer', ml: '5px' }} />
          </CustomWidthTooltip>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Button varient="text" component="label" startIcon={<QrCodeIcon />}>
            QRコード
             <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      const fileInput = e.target;
                      gUtils.readQrcode(e.target.files[0], (e, d) => {
                        if (d) {
                          if (gUtils.isLockModel(d.deviceModel)) {
                            if (gUtils.hasListObj(gCfp.cfpSsmDevices, [d], "deviceUUID")) {
                              ts({ open: true, msg: "接続済みです" })
                            } else {
                              gCfp.addSsmDevices(d)
                            }
                          } else {
                            ts({ open: true, msg: "このモデルはサポートされていません。" })
                          }
                        } else {
                          ts({
                            open: true,
                            msg: "読み取りに失敗しました。QRコードが正しいか確認してください。",
                          })
                        }
                        fileInput.value = '';
                      })
                    }}
                  />
          </Button>
          <Button
            variant="text"
  /*          disabled={getPersonalDevice === true}*/
            startIcon={<SmartphoneIcon />}
            onClick={(e) => {
           //     console.log("当前设备",getPersonalDevice,gCfp.allDevices,gCfp.cfpSsmDevices)
                if (!getPersonalDevice) {
                    setKeyList(   gCfp.allDevices.filter(item=>{
                        if (gUtils.isLockModel(item.deviceModel)){

                            return  !gCfp.cfpSsmDevices.some(ite=>ite.deviceUUID===item.deviceUUID)
                        }
                        return false
                    }))
                }else {
                    setKeyList([])
               }
                setGetPersonalDevice(!getPersonalDevice)
            }}

          >
            個人で登録済のデバイス
          </Button>
        </Box>
        <Hider show={keyList.length > 0}>
          <Grid item xs={12}>
            <Typography>入退室システムへ登録するデバイスを選択</Typography>
            <Box sx={{ mb: '5px' }}>
              {keyList.map((item, index) => {
                return (
                  <Button
                    key={index}
                    sx={{
                      marginRight: 1,
                      marginTop: 1,
                    }}
                    disabled={limitWarning === true}
                    variant="outlined"
                    onClick={() => {

                 //       console.log("添加设备QQQQQ",item)
                        gCfp.addSsmDevices(item).then(r=>{
                             setKeyList(prevState => prevState.filter(item=>item.deviceUUID!==item.deviceUUID))
                        })
                     /* setKeyWarning(false)
                      let tmp = [...keyList]
                      tmp.splice(index, 1)
                      setKeyList(tmp)
                      if (item.keyLevel === 0) {
                        setIsPending(true)
                      /!*  API.post('Attendance', `/device `, {
                          body: {
                            ...item,
                            companyID: gStripe.customer.id,
                          },
                        })
                          .then((res) => {
                            setLimitWarning(res)
                            API.get('Attendance', `/device`, {
                              queryStringParameters: {
                                companyID: gLoginEmployee.loginInfo.companyID,
                                access: JSON.stringify(
                                  gLoginEmployee.loginInfo.access,
                                ),
                              },
                            })
                              .then((res) => {
                                gCompanyDevice.setDevices(res)
                                setIsPending(false)
                              })
                              .catch((err) => console.log(err))
                          })
                          .catch((err) => console.log(err))*!/
                        console.log("添加认证设备",item)

                      } else {
                        setKeyWarning(true)
                      }*/
                    }}
                  >
                    {item.deviceName}
                  </Button>
                )
              })}
            </Box>
          </Grid>
        </Hider>
        <Hider show={false}>
          <Typography variant="h6" color="error">
            登録失敗、デバイスの"オーナー"権限が必要です
          </Typography>
        </Hider>
        <Hider show={limitWarning}>
          <Typography
            variant="h6"
            sx={{ color: 'error.main', textDecoration: 'underline' }}
            component={Link}
            to={'/settings'}
          >
            デバイス登録数が上限に達しました。アップグレードを行うと更にデバイスの追加ができます。
          </Typography>
        </Hider>
      </CardContent>
      <Hider show={false}>
        <CircularProgress
          sx={{
            position: 'fixed',
            margin: 'auto auto',
            zIndex: '101',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          color="primary"
        />
      </Hider>
    </Card>
  )
}
